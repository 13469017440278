import React from 'react';
import 'font-awesome/css/font-awesome.min.css';

const TeamCard = ({ data }) => {
    const { img, name, designation, email, phone } = data;
    return (
        <div className="col-lg-4 col-md-4">
            <div className="team-block" style={{ position: 'relative' }}>
                <img src={img} alt="img" style={{width: '100%', height: 'auto'}}/>
                
                <div style={{marginTop: '10px'}}>
                    <h5 className="text-white" style={{display: 'flex', alignItems: 'center'}}>
                        <a href="/team-details">{name}</a>
                        <div className="overlay-icons" style={{ marginLeft: '10px', display: 'flex' }}>
                            {email && (
                                <a href={`mailto:${email}`} style={{ marginRight: '10px' }}>
                                    <i className="fa fa-envelope text-white" aria-hidden="true"></i>
                                </a>
                            )}
                            {phone && (
                                <a href={`tel:${phone}`}>
                                    <i className="fa fa-phone text-white" aria-hidden="true"></i>
                                </a>
                            )}
                        </div>
                    </h5>
                    <h6 className="text-uppercase text-olive">{designation}</h6>
                </div>
            </div>
        </div>
    );
};

export default TeamCard;
