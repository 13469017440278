import React from 'react';
import PageHeader02 from '../../components/PageHeader02';
import ServiceContain from '../../containers/ServiceContain';
import Pdfs from '../../containers/pdfs';

const Services = () => {
    return (
        <><br></br>
        <br></br>
            <PageHeader02 title="Þjónusta" />
           
            <ServiceContain />
            <Pdfs />
        </>
    );
};

export default Services;