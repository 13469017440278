import React from 'react';
import { EffectFade, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import PageHeder from '../../components/PageHeder';
import Paragraph from '../../components/Paragraph';
import ProjectCard09 from '../../components/ProjectElements/ProjectCard09';

import SectionHeader from '../../components/SectionHeader';

const ProjectGallery09 = () => {
    const ProjectData01 = [
{
        id: '1',
        img: 'images/portfolio/packery/2.jpg',
        details: 'Háspennuráðgjöf',
        tag: 'iðnaður'
    },
    {
        id: '2',
        img: 'images/portfolio/packery/3.jpg',
        details: 'Iðnaðar- og húsarafmagn ',
        tag: 'veita'
    },
    {
        id: '3',
        img: 'images/portfolio/packery/6.jpg',
        details: 'Skápasmíði',
        tag: 'endurnýtanlegt'
    },
    {
        id: '4',
        img: 'images/portfolio/packery/10.jpg',
        details: 'Sjálfvirk Stýrikerfi',
        tag: 'sjálfvirkni'
    },
    {
        id: '5',
        img: 'images/portfolio/packery/11.jpg',
        details: 'Sjálfbært Orkustjórnun',
        tag: 'sjálfbærni'
    },

    ];


    return (
        <section className="projects packery">
         
            <div className="large_font">
                <SectionHeader title="VERKEFNI" />
            </div>
            <div className="container">
                <div className="section-header text-center ">
                     <PageHeder className='text-white' title="Ýmis Verkefni"></PageHeder>
                    <div className="section-desc row align-items-center justify-content-center">
                        <div className="col-lg-6 text-end">
                            <Paragraph text="Raftvistur ehf. er alhliða rafvirkjafyrirtæki og tekur að sér bæði há- og lágspennuverkefni."></Paragraph>
                        </div>
                        <div className="col-lg-6 text-start">
                            <Paragraph text="Við leggjum áherslu á gæði, nákvæmni og áreiðanleika í öllum verkefnum. Svipmyndir frá okkar verkum."></Paragraph>
                        </div>
                    </div>
                </div>

                <div className="swiper swiper_gallery_2">
                    <Swiper
                        modules={[Navigation, EffectFade]}
                        effect="slide"
                        breakpoints={{
                            // 640: {
                            //     width: 640,
                            //     slidesPerView: 1,
                            // },
                            // 768: {
                            //     width: 768,
                            //     slidesPerView: 2,
                            // },
                            // 992: {
                            //     width: 920,
                            //     slidesPerView: 2,
                            // },

                            600: {
                                slidesPerView: 2,
                            },
                            992: {
                                slidesPerView: 3,
                            },
                        }}
                        spaceBetween={30}
                        loop={true}
                        navigation={{
                            prevEl: '.swiper-button-prev',
                            nextEl: '.swiper-button-next',
                        }}
                    >
                        {
                            ProjectData01.map(data => <SwiperSlide> <ProjectCard09
                                key={data.id}
                                data={data}
                            /></SwiperSlide>)
                        }
                    </Swiper>

                    {/* <!-- Add Buttons --> */}
                    <div className="swiper-button-prev details_link l-dir pagination-previous"><a aria-label='contact button' alt="contact button"  href="/contact"><span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></a></div>
                    <div className="swiper-button-next details_link r-dir pagination-next"><a aria-label='contact button' alt="contact button"  href="/contact"><span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></a></div>
                </div>

            </div>
        </section>
    );
};

export default ProjectGallery09;