import React from 'react';
import Articlespdfs from '../components/about/Articlespdfs';
import FormContainer from "../containers/FormContainer";
import FunFacts from '../containers/FunFacts/FunFacts';
import MissionContainer from "../containers/MissionContainer";


import TextAbout from './TextAbout';

const AboutUs = () => {
   

    return (
        <main className="wrapper">
            <MissionContainer />

            <div className="video-block" >
            <Articlespdfs />
            </div>

            <FunFacts className='funfacts pd-top-lg bg_2' />
           
            <TextAbout />
           
            <FormContainer />
        </main>
    );
};

export default AboutUs;