import React from "react";

const Pdfs = () => {
  return (
    <main className="wrapper">
      <section className="services inner in-service pb-0">
        <div className="container">
          <div className="row">
            <div className="pdfiles">
              <div className="col-lg-4">
                <button aria-label="toggler homepage"  className="btn olive" data-bs-toggle="dropdown">
                  Sudkabel Búnaður
                </button>
                <div
                  className="dropdown-menu"
                  style={{
                    position: "abssolute",
                    border: "none",
                    backgroundColor: "transparent",
                    zIndex: 9999,
                  }}
                >
                  <li className="menu-item">
                    <a
                      href="/images/Terminations_and_Joints_for_XLPE-insulated_12-36_kV_Medium_Voltage_Cables.pdf"
                      download
                    >
                      ✔️ Terminations and Joints Medium Voltage Cables
                    </a>
                  </li>
                  <li className="menu-item">
                    <a
                      href="/images/Sudkabel-3005-E-Accessories-for- HV_EHV-cables-final.indd.pdf"
                      download
                    >
                      ✔️ Sudkabel Accessories for HV_EHV
                    </a>
                  </li>
                  <li className="menu-item">
                    <a
                      href="/images/Plug-in-terminations-for-xlpe-insulated-12-52-kV-medium-voltage-cables.pdf"
                      download
                    >
                      ✔️ Plug-in terminations for xlpe 12-52 kV Cables
                    </a>
                  </li>
                  <li className="menu-item">
                    <a
                      href="/images/2016-05-11-SÜDKABEL-HV-Accessories.pdf"
                      download
                    >
                      ✔️ SÜDKABEL HV Accessories
                    </a>
                  </li>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default Pdfs;
