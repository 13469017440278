import React from 'react';

const SliderCard = ({ data }) => {
    const { bgImg } = data;
    return (
        <div className="slider" style={{ backgroundImage: `url(${bgImg})` }}>
            <div className="slide_content">
                <div className="slide_content_wrapper mb-0 h-auto bg-dark-100">
                    <div className="slide_content_inner">
                        <div className="meta m-0">
                            <div className="category text-olive text-uppercase">Raftvistur ehf.</div>
                        </div>
                        <h4><a href="/about" className="text-white">Allt frá háspennu í iðnar- og húsarafmagn. Talaðu við sérfræðing!</a></h4>
                        <div className="details_link">
                            <a aria-label='contact redirect' alt="link to contact"  href="/contact">
                                <span className="link_text">Hafðu samband</span>
                                <span className="link_icon">
                                <span className="line">
  <svg >
    <path d="M0.5 17.5 L20 15 L14 20 L34 17.5" stroke="#a6a182"/>
  </svg>
</span>
                                    <span className="circle"></span>
                                    <span className="dot"></span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SliderCard;