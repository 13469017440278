import React from 'react';

const ProjectCard09 = ({ data }) => {
    const { img, details } = data;

    return (
        <div className='grid-item'>
            <div className="thumb">
                <img className="item_image" src={img} alt="" />
                <div className="works-info">
                    <div className="label-text">
                        <a aria-label='contact redirect' alt="link to contact"  href="/contact">Raftvistur ehf</a>
                       <a href="/works">{details}</a>
                        <div className="details_link"><a aria-label='contact redirect' alt="link to contact"  href="/contact"><span className="link_text">Hafðu samband</span> <span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></a></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectCard09;