import React from 'react';

const RunningProjectItem = (props) => {
    return (
        <div className="thumb">
            <img className="item_image" src={props.img} alt="img" />
            <div className="works-info">
                <div className="label-text">
                    <h3><a href="/Services">{props.name}</a></h3>
                   <a href="/Services">{props.details}</a>
                </div>
            </div>
        </div>

    );
};

export default RunningProjectItem;