import React from 'react';

const TextAbout = () => {
    return (
        <section className="highlight_banner bg-dark-200">
            <div className="container">
                <div className="row justify-content-center">
                <div className="col-lg-11 p-lg-0">
    <p className="about_para text-center">Áratuga reynsla með <span><a aria-label='contact button' alt="contact button"  href="/contact">traustu liði rafvirkja </a></span>horfum við stoltir fram á við með <span><a aria-label='contact button' alt="contact button"  href="/contact">öruggum og endingargóðum háspennubúnaði</a></span>.</p>
</div>
                </div>
            </div>
        </section>
    );
};

export default TextAbout;