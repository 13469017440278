import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';

const CustomMarker = () => {
    const center = {
        lat: 64.09472473555893,
        lng:   -21.815447420714733,
    };

    const [iconSize, setIconSize] = useState(100);
    const [customIcon, setCustomIcon] = useState(null);

    const step = 1;
    const framesPerSecond = 30;
    const totalFrames = 30;

    useEffect(() => {
        if (window.google) {
            setCustomIcon({
                url: '/images/logo-light.svg',
                scaledSize: new window.google.maps.Size(150, iconSize),
            });
        }
    }, [iconSize]);

    useEffect(() => {
        let frame = 0;
        let direction = step;

        const animateIcon = () => {
            frame += direction;

            if (frame >= totalFrames || frame <= 0) {
                direction = -direction;
            }

            setIconSize((prevSize) => prevSize + step * direction);
        };

        const intervalId = setInterval(() => {
            animateIcon();
        }, 1000 / framesPerSecond);

        return () => clearInterval(intervalId);
    }, []);

    return customIcon && <Marker position={center} icon={customIcon} />;
};

const Map = () => {
    const containerStyle = {
        width: '100%',
        height: '100%',
    };

    const center = {
        lat: 64.09472473555893,
        lng:   -21.815447420714733,
    };

    return (
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={18}
                mapTypeId={'satellite'}
            >
                <CustomMarker />
            </GoogleMap>
        </LoadScript>
    );
};

export default Map;
