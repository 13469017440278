import React, { useEffect, useState } from 'react';
import axios from 'axios';

const FunFacts = (props) => {
    const [time, setTime] = useState("");
    const [location, setLocation] = useState("");
    const [weather, setWeather] = useState("");
    const [weatherIcon, setWeatherIcon] = useState("");
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768);
        };
        
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(new Date().toLocaleTimeString());
        }, 1000);

     
        const lat = 64.0796243739284; 
        const lon = -21.805383636174163; 
     
       
        const apiKey = 'ac8054b0d2117345bf0462f0b1539feb';
        axios.get(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${apiKey}&lang=is`)
            .then(res => {
                setWeather(res.data.weather[0].description);
                setWeatherIcon(`https://openweathermap.org/img/wn/${res.data.weather[0].icon}.png`);
                setLocation(res.data.name);
            })
            .catch(error => {
                console.log("Unexpected Error:", error);
            });

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const flexDirection = isSmallScreen ? 'column' : 'row';

    return (
        <section className={props.className} id="funfacts">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="funfacts_inner" style={{ display: 'flex', flexDirection, alignItems: 'center', gap: '20px', color: '#eee', backgroundColor: 'rgba(34, 34, 34, 0.8)', padding: '40px 0' }}>
                            <h3 style={{ fontSize: '24px', margin: '0' }}> {location}</h3>
                            <h3 style={{ fontSize: '24px', margin: '0' }}>{time}</h3>
                            <h3 style={{ fontSize: '24px', margin: '0', display: 'flex', alignItems: 'center' }}>
                                {weather}
                                {weatherIcon && <img className="weather-icon" src={weatherIcon} alt={weather} style={{ width: '50px', height: '50px', marginLeft: '10px' }} />}
                            </h3>
                           
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FunFacts;
