import React from 'react';
import PageBanner from '../../containers/PageBanner';
import TeamContainer from '../../containers/TeamContainers/TeamContainer';
import TeamContainer02 from '../../containers/TeamContainers/TeamContainer02';
import FormContainer from "../../containers/FormContainer";

const Team = () => {
    return (
        <>
            <PageBanner pageTitle='Starfsmenn' />
            <main className="wrapper">
                <TeamContainer />
                <TeamContainer02 />
                <FormContainer/>
            </main>
        </>
    );
};

export default Team;