import { createBrowserRouter } from "react-router-dom";
import HomeLayout from "../Layouts/HomeLayout";


import AboutUs from "../Pages/AboutUs";


import Contact from "../Pages/Contact";
import ErrorPage from "../Pages/ErrorPage";
import Home01 from "../Pages/HomePages/Home01";



import Works from "../Pages/ProjectPage/Works";


import Services from "../Pages/ServicePage/Services";



import Team from "../Pages/Teampage/Team";



export const routes = createBrowserRouter([
    {
        path: "/",
        element: <HomeLayout/>,
        children: [
            {
                path: '/',
                element: <Home01/>,
            },
        
           
            {
                path: '/works',
                element: <Works/>,
            },
          
           
         
            {
                path: '/Services',
                element: <Services/>,
            },
           
           
          
            {
                path: '/team',
                element: <Team/>,
            },
         
            {
                path: '/contact',
                element: <Contact/>,
            },
            {
                path: '/about',
                element: <AboutUs/>,
            },
          
            {
                path: '*',
                element: <ErrorPage/>,
            },

        ]
   
      
    }
])