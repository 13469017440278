import React from 'react';
import IconBoxFlex from '../../components/IconBoxFlex';
import Paragraph from '../../components/Paragraph';
import SubHeading from '../../components/SubHeading';

const AboutContainer = () => {

    return (
        <section className="about bg-dark-100">
            <div className="large_font">
                <h2 className="floating_element text-dark-200 d-flex justify-content-center">Um okkur</h2>
            </div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-5">
                        <div className="about_image">
                            <img src="images/about/a1.jpg" alt="img" />
                            <img src="images/about/e1.svg" alt="About" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-7">
                        <div className="about_text_inner">
                            <div className="about_text">
                                <SubHeading title="Um Raftvist"></SubHeading>
                                <Paragraph text="Raftvistur ehf. var stofnað 1997 og sérhæfir sig í lausnum á háspennubúnaði og hefur sótt sér umboð hjá Südkabel í Þýskalandi. Með eljusemi og lausnamiðun í sérhæfðum verkefnum hefur fyrirtækið öðlast sérstöðu í háspennuverkefnum á Íslandi."></Paragraph>
                            </div>
    
                            <div className="about_icon_box">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <IconBoxFlex img="images/about/i2.svg" text="Háspenna"></IconBoxFlex>
                                    </div>
                                    <div className="col-lg-6">
                                        <IconBoxFlex img="images/about/i3.svg" text="Bilanagreining"></IconBoxFlex>
                                    </div>
                                    <div className="col-lg-6">
                                        <IconBoxFlex img="images/about/i1.svg" text="Sérverkefni"></IconBoxFlex>
                                    </div>
                                    <div className="col-lg-6">
                                        <IconBoxFlex img="images/about/i4.svg" text="Ráðgjöf"></IconBoxFlex>
                                    </div>
                                </div>
                            </div>
    
                            <div className="btn_group">
                                <a href="/about" className="btn gray">Lesa meira</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
    
};

export default AboutContainer;