import React from 'react';

const ServiceCard = (props) => {
    return (
        <div className="icon_box">
          
            <img src={props.imgDark} alt="Icon Box" className="dark" />
            <img src={props.imgLight} alt="Icon Box" className="light" />
            <h3 className="text-white"><a aria-label='contact redirect' alt="link to contact"  href="/contact">{props.title}</a></h3>
            <p className="text-gray-600">{props.text}</p>
            <div className="arrow_effect">
                <a aria-label='contact redirect' alt="link to contact" href="/contact"><span className="crossline1"></span><span className="crossline2"></span></a>
            </div>
        </div>

    );
};

export default ServiceCard;