import React from 'react';
import ServiceCard from '../components/ServiceCard';

const Services = () => {

    return (
        <section className="services bg-dark-100 pb-0">
            <div className="container">
                <div className="row">
                <div className="col-lg-4" >
    <ServiceCard  imgDark="images/icon_box/d1.svg" imgLight="images/icon_box/d1-light.svg" title="Háspenna" text="Sérhæfing í jarðstrengjum að 170kV. Útdráttur, samtengingar og endabúnaður." />
</div>
<div className="col-lg-4" >
    <ServiceCard imgDark="images/icon_box/d1.svg" imgLight="images/icon_box/d1-light.svg" title="Iðnaðar- og Húsarafmagn" text="Tökum að okkur verkefni fyrir fyrirtæki og heimili, framkvæmdir og viðhald." />
</div>
<div className="col-lg-4" >
    <ServiceCard  imgDark="images/icon_box/d1.svg" imgLight="images/icon_box/d1-light.svg" title="Skápasmíði" text="Setjum saman rafmagnstöflur frá helstu framleiðendum, auk sérverkefna á stærri rafmagnsskápum og skinnukerfum." />
</div>

                </div>
            </div>
        </section>
    );
};

export default Services;