import React from 'react';
import ServiceCard02 from '../components/ServiceCard02';

const ServiceContainer02 = () => {
    return (
        <main className="wrapper">
            <section className="services inner in-service pb-0">
                <div className="container">
                    <div className="row">
                        


                        
                    <div className="col-lg-4" >
    <ServiceCard02 img01='images/icon_box/bg-1.jpg' img02="images/icon_box/1.png" title="Háspenna " text="Raftvistur hefur tileinkað sér sérþekkingu sem snýr að jarðstrengjum, samtengingum og endabúnaði í háspennu á Íslandi. Við höfum umboð fyrir Südkabel háspennubúnaði á Íslandi, en þeir hafa verið leiðandi á Evrópskum markaði síðan 1898.  Südkabel eru þekktir fyrir framleiðslu á rafmagnsstrengjum fyrir allt að 525 kV, EHV (extra high voltage) tengdum búnaði auk vöruþróunnar í XLPE-einangrun." />
</div>

<div className="col-lg-4" >
    <ServiceCard02 img01="images/icon_box/bg-2.jpg" img02="images/icon_box/2.png" title="Iðnaðar og Húsarafmagn" text="Raftvistur ehf. hefur góða reynslu af ýmsum verkefnum tengdum iðnaði og fyrirtækjum. Mörg af stærstu verkefnum fyrirtækisins hefur snúið að framvæmdum og viðhaldi í gagnaverum, mjólkuriðnaði og líftæknifyrirtækjum. Einnig sinnum við skrifstofurýmum og húsarafmagni eftir eftirspurn." />
</div>

<div className="col-lg-4" >
    <ServiceCard02 img01="images/icon_box/bg-1.jpg" img02="images/icon_box/3.png" title="Skápasmíði" text="Setjum saman rafmagnstöflur frá helstu framleiðendum, auk sérverkefna á stærri rafmagnsskápum og skinnukerfum. Rafmagnstöfluvinna fer að mestu fram á verkstæði okkar áður en töflurnar eru fluttar á staðinn. Auk þess höfum við tekið að okkur skápasmíði fyrir stærri fyrirtæki af óskum kaupanda." />
</div>

                    </div>
                </div>
            </section>

        </main>
    );
};

export default ServiceContainer02;