import React from 'react';
import ContactContainer from '../containers/ContactContainer';
import PageBanner03 from '../containers/PageBanner03';

const Contact = () => {
    return (
        <>
            <PageBanner03  activePage="Hafa Samband" />
            <ContactContainer />
        </>
    );
};

export default Contact;