import React from "react";
import AboutContainer from "../../containers/AboutContainers/AboutContainer";
import FormContainer from "../../containers/FormContainer";
import FunFacts from "../../containers/FunFacts/FunFacts";
import ProjectGallery09 from "../../containers/ProjectContainers/ProjectGallery09";
import RunningProjects from "../../containers/RunningProjects";
import Services from "../../containers/Services";
import Sliders from "../../containers/SliderContainers/Sliders";

const Home01 = () => {
  return (
    <div className="wrapper">
      <Sliders />
      <Services />
      <AboutContainer />
      <FunFacts className="funfacts bg_1" />
      <RunningProjects />
      <ProjectGallery09 />
      <FormContainer />
    </div>
  );
};

export default Home01;
