import React from 'react';
import PageBanner03 from '../../containers/PageBanner03';
import ProjectGallery56 from '../../containers/ProjectContainers/ProjectGallery56';
import BlockGalleryItemCard from '../../containers/ProjectContainers/BlockGalleryItemCard';

const Works = () => {
    return (
        <>
            <PageBanner03  activePage="Verkefni" />
            <ProjectGallery56 text='Sjá Meira' className='btn gray' />
            <div className="project-details">
            <figure className="block-gallery">
                        <ul className="blocks-gallery-grid">
                         
                           
   
    <BlockGalleryItemCard img='/images/simplegallery/9.jpg' />
    <BlockGalleryItemCard img='/images/simplegallery/10.jpg' />
    <BlockGalleryItemCard img='/images/simplegallery/11.jpg' />
    <BlockGalleryItemCard img='/images/simplegallery/12.jpg' />
    <BlockGalleryItemCard img='/images/simplegallery/19.jpg' />
    <BlockGalleryItemCard img='/images/simplegallery/13.jpg' />
    <BlockGalleryItemCard img='/images/simplegallery/14.jpg' />
    <BlockGalleryItemCard img='/images/simplegallery/1.jpg' />
    <BlockGalleryItemCard img='/images/simplegallery/2.jpg' />
    <BlockGalleryItemCard img='/images/simplegallery/3.jpg' />
    <BlockGalleryItemCard img='/images/simplegallery/4.jpg' />
    <BlockGalleryItemCard img='/images/simplegallery/5.jpg' />
    <BlockGalleryItemCard img='/images/simplegallery/6.jpg' />
    <BlockGalleryItemCard img='/images/simplegallery/7.jpg' />
    <BlockGalleryItemCard img='/images/simplegallery/8.jpg' />
    <BlockGalleryItemCard img='/images/simplegallery/15.jpg' />
    <BlockGalleryItemCard img='/images/simplegallery/16.jpg' />
    <BlockGalleryItemCard img='/images/simplegallery/17.jpg' />
  


                        </ul>
                    </figure>
                    </div>

        </>
    );
};

export default Works;