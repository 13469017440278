import React from 'react';

const TeamCard02 = ({ data }) => {
    const { img, name,  phone, email } = data;

    return (
        <div className="team-block">
            <img src={img} alt="img" />        
            <div className="overlay-icons" style={{ zoom: '170%',marginTop: '20px', marginLeft: '0', display: 'flex' }}>
                            {email && (
                                <a href={`mailto:${email}`} style={{ marginRight: '10px' }}>
                                    <i className="fa fa-envelope text-white" aria-hidden="true"></i>
                                </a>
                            )}
                            {phone && (
                                <a href={`tel:${phone}`}>
                                    <i className="fa fa-phone text-white" aria-hidden="true"></i>
                                </a>
                            )}
                        </div>
            
            <h5 className="text-olive">{name}</h5>
         
          
        </div>
    );
};

export default TeamCard02;