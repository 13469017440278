import React from 'react';

const MissionContainer = () => {
    return (
        <section className="mission">
            <div className="container">
                <div className="mission_top_part">
                    <div className="section-header text-center">
                        <h6 className="text-white text-uppercase">Okkar MARKMIÐ</h6>
                        <p className="text-gray-600">Gæða búnaður fyrir háspennu, fagmenn með lausnir til frambúðar.</p>
                    </div>
                    <div className="has_line"></div>
                    <img src="images/bg/about_bg.jpg" alt="" />
                </div>
    
                <div className="mission_bottom_part">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 pe-lg-4">
                            <div className="section-header">
                                <h3 className="text-white text-uppercase border-line">Leiðandi í Háspennulausnum</h3>
                            </div>
                        </div>
                        <div className="col-lg-6 ps-lg-4">
                            <div className="mission_content">
                                <p>Raftvistur hefur tileinkað sér sérþekkingu í háspennuverkefni sem snúa að jarðstrengjum, samtengingum og endabúnaði. </p>
                                <p>Við bjóðum upp á alhliða þjónustu, allt frá ráðgjöf að uppsetningu, í há- og lágspennu í iðnaðar- og húsarafmagni. </p>
                                <p>Raftvistur er löggiltur rafverktaki með A- og B-löggildingu, meistara í rafvirkjun og rafveituvirkjun, auk annara réttinda í háspennutengingum upp að 170kV.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MissionContainer;